<template>
    <div class="index-container">
        <CommonFunctions />
        <MyTodo :todoList="todoList" />
        <DynamicList :todayOperateList="todayOperateList" />
    </div>
</template>

<script>
import { defineComponent, onMounted, reactive, toRefs, onUnmounted } from "vue";
import CommonFunctions from "./components/commonFunctions.vue";
import MyTodo from "./components/myTodo.vue";
import DynamicList from "./components/dynamicList.vue";
import { apiHomeIndexData } from "@/api";
import { useStore } from "vuex";
import { wsURL } from "@/utils/config";
export default defineComponent({
    components: {
        CommonFunctions,
        MyTodo,
        DynamicList,
    },
    setup() {
        const store = useStore();
        const state = reactive({
            todoList: [],
            todayOperateList: [],
            ws: null,
        });

        const getHomeData = async () => {
            let res = await apiHomeIndexData();
            if (res.status === "SUCCESS") {
                state.todoList = res?.data?.todoList;
                state.todayOperateList = res?.data?.todayOperateList;
            }
        };
        getHomeData();

        onUnmounted(() => {
            state.ws.onclose();
        });

        onMounted(() => {
            state.ws = new WebSocket(`${wsURL}/ws/ws-server`);
            state.ws.onmessage = evt => {
                if (JSON.parse(evt.data)?.data == "WORKBENCH_DATA_CHANGE") {
                    getHomeData();
                }
            };
            state.ws.onopen = () => {
                state.ws.send(
                    JSON.stringify({
                        cmd: "LOGIN",
                        clientType: "WEB",
                        message: store.getters["user/token"],
                    })
                );
            };
            state.ws.onclose = () => {};
        });

        return {
            ...toRefs(state),
        };
    },
});
</script>

<style lang="less" scoped>
.index-container {
    display: grid;
    grid-gap: 16px;
    grid-template-areas:
        "a1 a2"
        "a3 a2";
    grid-template-rows: 250px 1fr;
    grid-template-columns: 3fr 2fr;
    > div {
        background-color: #fff;
        padding: 16px;
        box-sizing: border-box;
        .header {
            margin-bottom: 16px;
        }
    }
}
</style>
