<template>
    <div class="my-todo">
        <div class="header">
            <a-badge :count="todoList.filter(item => !item.processed)?.length">
                <div>我的待办</div>
            </a-badge>
        </div>
        <div class="content">
            <a-empty v-show="!todoList.length" />
            <div
                class="todo-item"
                :style="{ color: item.processed ? 'rgba(0, 0, 0, 0.25)' : '' }"
                v-for="(item, index) in todoList"
                :key="index"
            >
                <span class="todo-title">{{ item.desc }}</span>
                <span
                    class="todo-status"
                    :style="{ color: item.processed ? 'rgba(0, 0, 0, 0.25)' : '#ff4d4f' }"
                >
                    {{ item.processed ? "已处理" : "未处理" }}
                </span>
                <span class="toto-date">{{ item.createdTime }}</span>
                <a-button
                    type="link"
                    @click="handleGoHandle(item.businessType)"
                    :disabled="item.processed"
                >
                    处理
                </a-button>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
export default defineComponent({
    props: ["todoList"],
    setup(props) {
        const { commit } = useStore();
        const { push } = useRouter();

        const handleGoHandle = type => {
            let path;
            if (type == "EXC_EVENT") {
                path = "/product-manage/abnormal-event";
            }
            push(path);
            commit("app/setSider", { groupName: "product-control", currentPath: path });
            //location.reload();
        };

        return {
            handleGoHandle,
        };
    },
});
</script>

<style lang="less" scoped>
:deep(.ant-badge-count) {
    right: -11px;
}
.my-todo {
    grid-area: a3;
    min-width: 700px;
    .header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 16px;
        color: #17233d;
        font-weight: bold;
        .all-read {
            color: @primary-color;
            cursor: pointer;
        }
    }
    .content {
        max-height: 70vh;
        padding: 16px 0;
        overflow-y: scroll;
        &::-webkit-scrollbar {
            display: none;
        }
    }
    .todo-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;
        .todo-title {
            flex: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding-right: 20px;
            box-sizing: border-box;
            width: 0;
        }
        .toto-date {
            display: inline-block;
            margin: 0 16px;
        }
        .todo-handle {
            color: @primary-color;
            cursor: pointer;
        }
    }
}
</style>
