<template>
    <div class="common-functions">
        <div class="header">常用功能</div>
        <div class="content">
            <div v-for="(item, index) in functions" :key="index" @click="handleGo(item)">
                <img :src="item.img" height="60" />
                {{ item.title }}
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import abnormalEvent from "@/assets/commonFunction/abnormalEvent.svg";
import abnormalHours from "@/assets/commonFunction/abnormalHours.svg";
import productorder from "@/assets/commonFunction/productorder.svg";
import productWorkorder from "@/assets/commonFunction/productWorkorder.svg";
import workorder from "@/assets/commonFunction/workorder.svg";
import workReportManagement from "@/assets/commonFunction/workReportManagement.svg";
export default defineComponent({
    setup() {
        const functions = [
            {
                title: "生产订单",
                img: productorder,
                path: "/product-manage/product-order",
                groupName: "product-plan",
            },
            {
                title: "集成生产工单",
                img: productWorkorder,
                path: "/product-manage/integrat-product-order",
                groupName: "product-plan",
            },
            {
                title: "集成工单作业",
                img: workorder,
                path: "/product-manage/integrat-workorder",
                groupName: "workorder-operate",
            },
            {
                title: "集成异常工时统计",
                img: abnormalHours,
                path: "/product-manage/integrat-abnormal-hours-statistics",
                groupName: "data-statistics",
            },
            {
                title: "异常事件",
                img: abnormalEvent,
                path: "/product-manage/abnormal-event",
                groupName: "product-control",
            },
            {
                title: "车间生产工单",
                img: productWorkorder,
                path: "/product-manage/workshop-product-order",
                groupName: "product-plan",
            },
            {
                title: "车间工单作业",
                img: workorder,
                path: "/product-manage/workshop-workorder",
                groupName: "workorder-operate",
            },
            {
                title: "车间异常工时统计",
                img: abnormalHours,
                path: "/product-manage/workshop-abnormal-hours-statistics",
                groupName: "data-statistics",
            },
        ];

        const { commit } = useStore();
        const { push } = useRouter();

        const handleGo = ({ path, groupName }) => {
            push(path);
            commit("app/setSider", { groupName, currentPath: path });
        };

        return {
            handleGo,
            functions,
        };
    },
});
</script>

<style lang="less" scoped>
.common-functions {
    grid-area: a1;
    .header {
        margin-bottom: 8px;
        color: #17233d;
        font-weight: bold;
    }
    .content {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-gap: 16px;
        height: calc(100% - 32px);
        // background: linear-gradient(to top, #6592f9, #3755f1);
        > div {
            flex: 1;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }
        .production-workshop-kanban {
            margin-right: 0;
        }
    }
}
</style>
