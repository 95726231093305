<template>
    <div class="dynamic-list">
        <div class="header">动态列表</div>
        <div class="content">
            <a-empty v-show="!todayOperateList.length" />
            <a-timeline>
                <a-timeline-item v-for="(item, index) in todayOperateList" :key="index">
                    <p style="color: rgba(0, 0, 0, 0.25)">{{ item.operateTime }}</p>
                    <p>{{ item.desc }}</p>
                </a-timeline-item>
            </a-timeline>
        </div>
    </div>
</template>

<script>
import { defineComponent, reactive } from "vue";
export default defineComponent({
    props: ["todayOperateList"],
});
</script>

<style lang="less" scoped>
// /deep/.ant-timeline-item {
//     width: 0;
//     flex: 1;
// }

.dynamic-list {
    grid-area: a2;
    min-width: 300px;
    min-height: calc(100vh - 140px);
    .header {
        margin-bottom: 16px;
        color: #17233d;
        font-weight: bold;
    }
    .content {
        max-height: 100vh;
        padding: 16px 0;
        overflow-y: scroll;
        &::-webkit-scrollbar {
            display: none;
        }
        .timeline-item {
            display: flex;
            .dynamic-list-time {
                width: 160px;
                display: inline-block;
            }
            .dynamic-list-content {
                display: inline-block;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                width: 100%;
            }
        }
    }
}
</style>
